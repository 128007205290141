@media (min-width: 0px) {
    body {
        font-family: 'Inter', sans-serif;
        margin: 0;
        min-width: 450px;
    }
    .strat-header {
        text-align: center;
        margin-top: 0;
    }
    .strat-btns{
        display: grid;
    }
    .strat-btns > button {
        margin-top: 10px;
        margin-bottom: 10px;
        margin-left: 20px;
        margin-right: 20px;
    }
    #scroll-credit{
        height: 580px;
        width: 425px;
        overflow-y: scroll;
        margin-left: 25px;
        margin-bottom: 5px;
        margin-top: 2px;
        border-top-left-radius: 15px;
        box-shadow: #d1d1d1 0px 0px 4px;
    }
    #scroll-short{
        height: 580px;
        width: 425px;
        overflow-y: scroll;
        margin-left: 25px;
        margin-bottom: 5px;
        margin-top: 2px;
        border-top-left-radius: 15px;
        box-shadow: #d1d1d1 0px 0px 4px;
    }
    #scroll-covered{
        height: 580px;
        width: 425px;
        overflow-y: scroll;
        margin-left: 25px;
        margin-bottom: 5px;
        margin-top: 2px;
        border-top-left-radius: 15px;
        box-shadow: #d1d1d1 0px 0px 4px;
    }
    #gaps{
        height: 560px;
        width: 470px;
        margin-left: 25px;
        margin-bottom: 5px;
        margin-top: 2px;
        border-top-left-radius: 15px;
        box-shadow: #d1d1d1 0px 0px 4px;
    }
    #straddle-income{
        height: 560px;
        width: 470px;
        margin-left: 25px;
        margin-bottom: 5px;
        margin-top: 2px;
        border-top-left-radius: 15px;
        box-shadow: #d1d1d1 0px 0px 4px;
    }
    #unusual-volume{
        height: 560px;
        width: 470px;
        margin-left: 25px;
        margin-bottom: 5px;
        margin-top: 2px;
        border-top-left-radius: 15px;
        box-shadow: #d1d1d1 0px 0px 4px;
    }
    #stock-tables-credit-sprd {
        border-collapse: collapse;
        margin-left: auto;
        margin-right: auto;
        margin-top: 20px;
        width: 425px;
    }
    #stock-tables-short-puts {
        border-collapse: collapse;
        margin-left: auto;
        margin-right: auto;
        margin-top: 20px;
        width: 425px;
    }
    #stock-tables-covered-calls {
        border-collapse: collapse;
        margin-left: auto;
        margin-right: auto;
        margin-top: 20px;
        width: 425px;
    }
    td {
        padding: 2px;
        overflow: hidden;
        border: 1px solid #ececec ;
        font-size: 8px;
        text-align: center;
    }
    th {
        padding: 2px;
        padding-left: 0px;
        padding-right: 0px;
        overflow: hidden;
        color: white;
        background-color: #47a9ee;
        font-size: 8px;
    }
    #credit-spread-btn, #short-puts-btn, #covered-calls-btn,
    #gaps-btn, #volume-btn, #straddle-btn, #long-call-btn, 
    #short-call-btn, #long-put-btn, #short-put-btn {
        font-size: 20px;
        color: rgb(255, 255, 255);
        background-color: #47a9ee;
        border-style: none;
        border-bottom-style: solid;
        border-bottom-width: 1px;
        border-bottom-color: #3d8dc7;
        margin: 0;
        padding: 15px;
        width: 100%;
    }
    .footer-info {
        background-color: #47a9ee;
        margin-top: 20px;
        padding: 0.5vh;
    }
}

@media (min-width: 700px) {
    body {
        font-family: 'Inter', sans-serif;
        margin: 0;
    }
    .strat-header {
        text-align: center;
        margin-top: 0;
    }
    #scroll-credit{
        height: 580px;
        width: 590px;
        overflow-y: scroll;
        margin-left: 25px;
        margin-bottom: 5px;
        margin-top: 2px;
        border-top-left-radius: 15px;
        box-shadow: #d1d1d1 0px 0px 4px;
    }
    #scroll-short{
        height: 580px;
        width: 590px;
        overflow-y: scroll;
        margin-left: 25px;
        margin-bottom: 5px;
        margin-top: 2px;
        border-top-left-radius: 15px;
        box-shadow: #d1d1d1 0px 0px 4px;
    }
    #scroll-covered{
        height: 580px;
        width: 590px;
        overflow-y: scroll;
        margin-left: 25px;
        margin-bottom: 5px;
        margin-top: 2px;
        border-top-left-radius: 15px;
        box-shadow: #d1d1d1 0px 0px 4px;
    }
    #gaps{
        height: 560px;
        width: 590px;
        margin-left: 25px;
        margin-bottom: 5px;
        margin-top: 2px;
        border-top-left-radius: 10px;
        box-shadow: #d1d1d1 0px 0px 4px;
    }
    #straddle-income{
        height: 560px;
        width: 590px;
        margin-left: 25px;
        margin-bottom: 5px;
        margin-top: 2px;
        border-top-left-radius: 10px;
        box-shadow: #d1d1d1 0px 0px 4px;
    }
    #unusual-volume{
        height: 560px;
        width: 590px;
        margin-left: 25px;
        margin-bottom: 5px;
        margin-top: 2px;
        border-top-left-radius: 10px;
        box-shadow: #d1d1d1 0px 0px 4px;
    }
    #stock-tables-credit-sprd {
        border-collapse: collapse;
        margin: 0;
        width: 530px;
    }
    #stock-tables-short-puts {
        border-collapse: collapse;
        margin: 0;
        width: 530px;
    }
    #stock-tables-covered-calls {
        border-collapse: collapse;
        margin: 0;
        width: 530px;
    }
    td {
        padding: 2px;
        overflow: hidden;
        border: 1px solid #ececec ;
        font-size: 11px;
        text-align: center;
    }
    th {
        padding: 2px;
        padding-left: 0px;
        padding-right: 0px;
        overflow: hidden;
        color: white;
        background-color: #47a9ee;
        font-size: 11px;
    }
    .content{
        display: grid;
        grid-template-columns: 22% 80%;
    }
    #credit-spread-btn, #short-puts-btn, #covered-calls-btn,
    #gaps-btn, #volume-btn, #straddle-btn, #long-call-btn, 
    #short-call-btn, #long-put-btn, #short-put-btn {
        font-size: 16px;
        color: #3d8dc7;
        background-color: #ffffff;
        border-style: none;
        border-bottom-style: solid;
        border-bottom-width: 1px;
        border-bottom-color: #e5e5e5;
        margin: 0;
        padding: 15px;
        width: 100%;
    }
    #credit-spread-btn{
        border-top-style: solid;
        border-top-width: 1px;
        border-top-color: #e5e5e5;
    }
    .footer-info {
        background-color: #47a9ee;
        margin-top: 60px;
        padding: 2.5vh;
    }
}

@media (min-width: 1000px) {
    body {
        font-family: 'Inter', sans-serif;
        margin: 0;
    }
    .strat-header {
        text-align: center;
        margin-top: 0;
    }
    .strat-btns{
        display: grid;
    }
    .strat-btns > button {
        margin-top: 10px;
        margin-bottom: 10px;
        margin-left: auto;
        margin-right: auto;
    }
    #scroll-credit{
        height: 580px;
        width: 758px;
        overflow-y: scroll;
        margin-left: 25px;
        margin-bottom: 5px;
        margin-top: 2px;
        border-top-left-radius: 15px;
        box-shadow: #d1d1d1 0px 0px 4px;
    }
    #scroll-short{
        height: 580px;
        width: 758px;
        overflow-y: scroll;
        margin-left: 25px;
        margin-bottom: 5px;
        margin-top: 2px;
        border-top-left-radius: 15px;
        box-shadow: #d1d1d1 0px 0px 4px;
    }
    #scroll-covered{
        height: 580px;
        width: 758px;
        overflow-y: scroll;
        margin-left: 25px;
        margin-bottom: 5px;
        margin-top: 2px;
        border-top-left-radius: 15px;
        box-shadow: #d1d1d1 0px 0px 4px;
    }
    #gaps{
        height: 560px;
        width: 758px;
        margin-left: 25px;
        margin-bottom: 5px;
        margin-top: 2px;
        border-top-left-radius: 15px;
        box-shadow: #d1d1d1 0px 0px 4px;
    }
    #straddle-income{
        height: 560px;
        width: 758px;
        margin-left: 25px;
        margin-bottom: 5px;
        margin-top: 2px;
        border-top-left-radius: 15px;
        box-shadow: #d1d1d1 0px 0px 4px;
    }
    #unusual-volume{
        height: 560px;
        width: 758px;
        margin-left: 25px;
        margin-bottom: 5px;
        margin-top: 2px;
        border-top-left-radius: 15px;
        box-shadow: #d1d1d1 0px 0px 4px;
    }
    #stock-tables-credit-sprd {
        border-collapse: collapse;
        margin: 0;
        width: 750px;
    }
    #stock-tables-short-puts {
        border-collapse: collapse;
        margin: 0;
        width: 750px;
    }
    #stock-tables-covered-calls {
        border-collapse: collapse;
        margin: 0;
        width: 750px;
    }
    td {
        padding: 2px;
        overflow: hidden;
        border: 1px solid #ececec ;
        font-size: 11px;
        text-align: center;
    }
    th {
        padding: 2px;
        padding-left: 0px;
        padding-right: 0px;
        overflow: hidden;
        color: white;
        background-color: #47a9ee;
        font-size: 11px;
    }
    .content{
        display: grid;
        grid-template-columns: 22% 80%;
    }
    #credit-spread-btn, #short-puts-btn, #covered-calls-btn,
    #gaps-btn, #volume-btn, #straddle-btn, #long-call-btn, 
    #short-call-btn, #long-put-btn, #short-put-btn {
        font-size: 15px;
        color: #3d8dc7;
        background-color: #ffffff;
        border-style: none;
        border-bottom-style: solid;
        border-bottom-width: 1px;
        border-bottom-color: #e5e5e5;
        margin: 0;
        padding: 13px;
        width: 100%;
    }
    #credit-spread-btn{
        border-top-style: solid;
        border-top-width: 1px;
        border-top-color: #e5e5e5;
    }
    .footer-info {
        background-color: #47a9ee;
        margin-top: 60px;
        padding: 2.5vh;
    }
}

@media (min-width: 1350px) {
    body {
        font-family: 'Inter', sans-serif;
        overflow-x: hidden;
        margin: 0;
    }
    .strat-header {
        text-align: center;
        margin-top: 0;
    }
    .strat-btns{
        display: grid;
    }
    .strat-btns > button {
        margin-top: 10px;
        margin-bottom: 10px;
        margin-left: auto;
        margin-right: auto;
    }
    #scroll-credit{
        height: 580px;
        width: 1008px;
        overflow-y: scroll;
        margin-left: 25px;
        margin-bottom: 5px;
        margin-top: 2px;
        border-top-left-radius: 15px;
        box-shadow: #d1d1d1 0px 0px 4px;
    }
    #scroll-short{
        height: 580px;
        width: 1008px;
        overflow-y: scroll;
        margin-left: 25px;
        margin-bottom: 5px;
        margin-top: 2px;
        border-top-left-radius: 15px;
        box-shadow: #d1d1d1 0px 0px 4px;
    }
    #scroll-covered{
        height: 580px;
        width: 1008px;
        overflow-y: scroll;
        margin-left: 25px;
        margin-bottom: 5px;
        margin-top: 2px;
        border-top-left-radius: 15px;
        box-shadow: #d1d1d1 0px 0px 4px;
    }
    #gaps{
        height: 560px;
        width: 995px;
        margin-left: 25px;
        margin-bottom: 5px;
        margin-top: 2px;
        border-top-left-radius: 15px;
        box-shadow: #d1d1d1 0px 0px 4px;
    }
    #straddle-income{
        height: 560px;
        width: 995px;
        margin-left: 25px;
        margin-bottom: 5px;
        margin-top: 2px;
        border-top-left-radius: 15px;
        box-shadow: #d1d1d1 0px 0px 4px;
    }
    #unusual-volume{
        height: 560px;
        width: 995px;
        margin-left: 25px;
        margin-bottom: 5px;
        margin-top: 2px;
        border-top-left-radius: 15px;
        box-shadow: #d1d1d1 0px 0px 4px;
    }
    #stock-tables-credit-sprd {
        border-collapse: collapse;
        margin: 0;
        width: 1000px;
    }
    #stock-tables-short-puts {
        border-collapse: collapse;
        margin: 0;
        width: 1000px;
    }
    #stock-tables-covered-calls {
        border-collapse: collapse;
        margin: 0;
        width: 1000px;
    }
    td {
        padding: 2px;
        overflow: hidden;
        border: 1px solid #ececec ;
        font-size: 11px;
        text-align: center;
    }
    th {
        padding: 2px;
        padding-left: 0px;
        padding-right: 0px;
        overflow: hidden;
        color: white;
        background-color: #47a9ee;
        font-size: 11px;
    }
    .content{
        display: grid;
        grid-template-columns: 19% 87%;
    }
    #credit-spread-btn, #short-puts-btn, #covered-calls-btn,
    #gaps-btn, #volume-btn, #straddle-btn, #long-call-btn, 
    #short-call-btn, #long-put-btn, #short-put-btn {
        font-size: 15px;
        color: #3d8dc7;
        background-color: #ffffff;
        border-style: none;
        border-bottom-style: solid;
        border-bottom-width: 1px;
        border-bottom-color: #e5e5e5;
        margin: 0;
        padding: 13px;
        width: 100%;
    }
    #credit-spread-btn{
        border-top-style: solid;
        border-top-width: 1px;
        border-top-color: #e5e5e5;
    }
    .footer-info {
        background-color: #47a9ee;
        margin-top: 60px;
        padding: 2.5vh;
    }
}

@media (min-width: 1640px) {
    body {
        font-family: 'Inter', sans-serif;
        margin: 0;
    }
    .strat-header {
        text-align: center;
        position: sticky;
        margin-top: 0;
    }
    .strat-btns{
        display: grid;
    }
    .strat-btns > button {
        margin-top: 10px;
        margin-bottom: 10px;
        margin-left: auto;
        margin-right: auto;
    }
    #scroll-credit{
        height: 580px;
        width: 1308px;
        overflow-y: scroll;
        margin-left: 25px;
        margin-bottom: 5px;
        margin-top: 2px;
        border-top-left-radius: 15px;
        box-shadow: #d1d1d1 0px 0px 4px;
    }
    #scroll-short{
        height: 580px;
        width: 1308px;
        overflow-y: scroll;
        margin-left: 25px;
        margin-bottom: 5px;
        margin-top: 2px;
        border-top-left-radius: 15px;
        box-shadow: #d1d1d1 0px 0px 4px;
    }
    #scroll-covered{
        height: 580px;
        width: 1308px;
        overflow-y: scroll;
        margin-left: 25px;
        margin-bottom: 5px;
        margin-top: 2px;
        border-top-left-radius: 15px;
        box-shadow: #d1d1d1 0px 0px 4px;
    }
    #gaps{
        height: 560px;
        width: 995px;
        margin-left: 25px;
        margin-bottom: 5px;
        margin-top: 2px;
        border-top-left-radius: 15px;
        box-shadow: #d1d1d1 0px 0px 4px;
    }
    #straddle-income{
        height: 560px;
        width: 995px;
        margin-left: 25px;
        margin-bottom: 5px;
        margin-top: 2px;
        border-top-left-radius: 15px;
        box-shadow: #d1d1d1 0px 0px 4px;
    }
    #unusual-volume{
        height: 560px;
        width: 995px;
        margin-left: 25px;
        margin-bottom: 5px;
        margin-top: 2px;
        border-top-left-radius: 15px;
        box-shadow: #d1d1d1 0px 0px 4px;
    }
    #stock-tables-credit-sprd {
        border-collapse: collapse;
        margin-top: 0;
        width: 1300px;
    }
    #stock-tables-short-puts {
        border-collapse: collapse;
        margin: 0;
        width: 1300px;
    }
    #stock-tables-covered-calls {
        border-collapse: collapse;
        margin: 0;
        width: 1300px;
    }
    td {
        padding: 5px;
        overflow: hidden;
        border: 1px solid #ececec ;
        font-size: 16px;
        text-align: center;
    }
    th {
        padding: 5px;
        overflow: hidden;
        color: white;
        background-color: #47a9ee;
        font-size: 16px;
    }
    .content{
        display: grid;
        grid-template-columns: 22% 84%;
    }
    #credit-spread-btn, #short-puts-btn, #covered-calls-btn,
    #gaps-btn, #volume-btn, #straddle-btn, #long-call-btn, 
    #short-call-btn, #long-put-btn, #short-put-btn {
        font-size: 20px;
        color: #3d8dc7;
        background-color: #ffffff;
        border-style: none;
        border-bottom-style: solid;
        border-bottom-width: 1px;
        border-bottom-color: #e5e5e5;
        margin: 0;
        padding: 15px;
        width: 100%;
    }
    #credit-spread-btn{
        border-top-style: solid;
        border-top-width: 1px;
        border-top-color: #e5e5e5;
    }
    .footer-info {
        background-color: #47a9ee;
        margin-top: 70px;
        padding: 4vh;
    }

}

::-webkit-scrollbar {
    width: 7px;
    height: 5px;
    margin-top: 30px;
}

::-webkit-scrollbar-track {
    width: 2px;
    background-color: #ffffff00;
    margin-top: 30px;
}

::-webkit-scrollbar-thumb {
    background: rgb(192, 192, 192);
    border-radius: 1px;
}
.group-title {
    display: flex;
    justify-content: center;
    background-color: #2193e4;
    height: 40px;
}
.group-title > h2 {
    color: #fcfcfc;
    margin-left: auto;
    margin-right: auto;
    font-size: 20px;
}
.nav {
    background-color: #2193e4;
}
.nav-items {
    color: #fcfcfc;
    font-weight: 400;
    font-size: 16px;
    display: flex;
    justify-content: right;
    padding: 10px;
    padding-top: 5px;
    padding-bottom: 0;
    margin: 0;
    margin-right: 35px;
}
.nav-items > li {
    list-style: none;
    padding-bottom: 5px;
    padding-left: 20px;
    padding-right: 20px;
    border-bottom: 4px solid rgba(255, 255, 255, 0);
}
.nav-items > li:hover {
    border-bottom: 4px solid white;
    cursor: pointer;
}
.contact-link{
    position: relative;
}
.contact-info{
    position: absolute;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: 10px;
    top: 74px;
    right: 1px;
    background-color: white;
    color: grey;
    display: none;
}
#email-link {
    text-decoration: none;
    color: #6d6d6d;
}
#contact-box:hover {
    background-color: rgb(236, 236, 236);
}
.time-tracker{
    font-size: 13px;
    font-weight: 400;
    display: flex;
    justify-content: center;
    margin: 5px;
    color: rgb(189, 0, 0);
}
#update{
    margin-top: 5px;
    margin-bottom: 0px;
}
#time{
    margin-left: 7px;
    margin-top: 5px;
    margin-bottom: 0px;
}
.all-tables {
    width: auto;
    margin-top: 50px;
}
th {
    position: sticky;
    top: 0;
}
table {
    margin-top: 20px;
    margin: 0;
}
tr {
    border: 0px solid #ffffff;
    padding: 5px;
}
tr:nth-child(even){
    background-color: #f2f2f2;
}
.sticky{
    border-right-style: solid;
    border-right-width: 1px;
    background-color: white;
    border-right-color: #e5e5e5;
    box-shadow: 1px 0px 4px rgb(236, 236, 236);
}
.strat-exp > h4 {
    margin-bottom: 15px;
    text-align: center;
}
#long-call-btn {
    border-top-style: solid;
    border-top-width: 1px;
    border-top-color: #e5e5e5;
}
#credit-spread-btn:hover, #short-puts-btn:hover,
#covered-calls-btn:hover, #gaps-btn:hover, #volume-btn:hover, #straddle-btn:hover{
    background-color: #f3f3f3;
}
#strat-desc-long-call, #strat-desc-long-put,
#strat-desc-short-call, #strat-desc-short-put {
    display: none;
}
#strat-desc-long-call, #strat-desc-short-call,
#strat-desc-long-put, #strat-desc-short-put {
    width: 600px;
    height: 500px;
    overflow-y: scroll;
    overflow-x: hidden;
    position: absolute;
    top: 100px;
    left: 34%;
    right: 50%;
    padding: 20px;
    border-radius: 20px;
    box-shadow: #5a5a5a78 0px 0px 15px;
    background-color: white;
}
#long-call-btn:hover, #short-call-btn:hover,
#long-put-btn:hover, #short-put-btn:hover {
    background-color: #f3f3f3;
}
#desc-close-lc, #desc-close-lp, #desc-close-sc, #desc-close-sp {
    position: relative;
    color: red;
    left: 560px;
    text-decoration-line: underline;
}
#desc-close-lc:hover, #desc-close-lp:hover,
#desc-close-sc:hover, #desc-close-sp:hover {
    cursor: pointer;
}
.signup {
    background-color: rgb(249, 249, 249);
    width: fit-content;
    height: fit-content;
    padding: 30px;
    margin-top: 130px;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    box-shadow: #e0e0e0 0px 0px 10px;
    border-radius: 10px;
}
.signup-fields {
    display: grid;
    width: auto;
    justify-content: center;
}
.signup-fields > input {
    margin-bottom: 35px;
    height: 30px;
    width: 400px;
    border-radius: 7px;
    border-style: solid;
    border-width: 2px;
    border-color: #00a2ff;
}
.signup-btns {
    display: flex;
    justify-content:space-between;
}
.signup-btns > button {
    cursor: pointer;
    padding: 10px;
    border-radius: 5px;
    border-style: none;
    font-size: large;
    color: white;
    background-color: #00a2ff;
}
.signup-btns > span:hover {
    cursor: pointer;
    color: #00a2ff;
    margin-bottom: 10px;
}
.signup-btns-opt > span {
    display: block;
}
.signup-btns-opt > span:hover {
    cursor: pointer;
    color: #00a2ff;
}
#forgot-text {
    width: 400px;
}
.msg {
    color: red;
    font-size: 14px;
}
.footer-info {
    background-color: #2193e4;
    margin-top: 0;
    padding: 120px;
}
#footer-text {
    color: white;
    text-align: right;
}